'use client';
import React, { useEffect } from 'react';
import { usePathname } from 'next/navigation';
import Link from 'next/link';
import Image from 'next/image';
import ServerConfig from '../../config/ServerConfig';
import NewsletterSignupForm from './NewsletterSignupForm';

export type NewsletterSignupFormValues = {
	email: string;
	name?: string;
};

export default function Footer() {
	const pathname = usePathname();

	useEffect(() => {
		const updateFooterBackground = () => {
			const previousSection = document.querySelector('section.light-bg, section.dark-bg');
			const footer = document.querySelector('.footer-container') as HTMLElement;
			if (previousSection && footer) {
				if (previousSection.classList.contains('light-bg')) {
					footer.style.backgroundColor = '#345237';
				} else if (previousSection.classList.contains('dark-bg')) {
					footer.style.backgroundColor = '#677D68';
				}
			}
		};
		updateFooterBackground();
	}, [pathname]);

	return (
		<section className="footer-container">
			<footer>
				<nav className="footer-links">
					<Link href="/about-ndc-possums">about possums/ndc</Link>
					<Link href="/free-resources">free resources</Link>
					<Link href="/evidence-base">ndc evidence base</Link>
					<Link href="/books-by-drpam">dr pam’s books</Link>
					<Link href="/clinic">clinic</Link>
					<Link href="/blog">blog</Link>
					<Link href="/social-enterprise">our social enterprise</Link>
					<Link href="/faq-and-contact">FAQs / contact us</Link>
					<Link href="/privacy-policy">privacy policy</Link>
					<Link href="/terms-and-conditions">terms &amp; conditions</Link>
				</nav>
				<div className="newsletter">
					<h2>Stay updated with Possums</h2>
					<p>
						We send you news, updates, and offers relating to NDC and the Possums programs. See our{' '}
						<Link href={'/privacy-policy'} className="custom-link">
							Privacy Policy
						</Link>{' '}
						for more information.
					</p>
					<NewsletterSignupForm />
				</div>
			</footer>
			<div className="social-media">
				<Link
					href={ServerConfig.GetDrPamInstagramUrl()}
					target="_blank"
					rel="noopener noreferrer"
					aria-label="Instagram"
				>
					<Image src="/images/icons/Icon_Instagram.svg" alt="Instagram" width={50} height={50} />
				</Link>
				<Link
					href={ServerConfig.GetDrPamLinkedinUrl()}
					target="_blank"
					rel="noopener noreferrer"
					aria-label="LinkedIn"
				>
					<Image src="/images/icons/Icon_Linkedin.svg" alt="LinkedIn" width={50} height={50} />
				</Link>
				<Link
					href={ServerConfig.GetDrPamFacebookUrl()}
					target="_blank"
					rel="noopener noreferrer"
					aria-label="Facebook"
				>
					<Image src="/images/icons/Icon_Facebook.svg" alt="Facebook" width={50} height={50} />
				</Link>
			</div>
			<div className="logo-overlay">
				<Image src="/images/icons/Icon_Possums.svg" alt="Dr. Pam Logo" width={80} height={80} />
			</div>
		</section>
	);
}
