'use client';
import React from 'react';
import { useForm, zodResolver } from '@mantine/form';
import z from 'zod';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import FormControl from '@dr-pam/common-components/Components/Form/FormControl';
import FetchUtils from '@dr-pam/common-components/Utils/FetchUtils';
import Form from '@dr-pam/common-components/Components/Form/Form';
import TextInput from '@dr-pam/common-components/Components/Form/TextInput';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';

const validationSchema = z.object({
	email: z.string().email('Invalid email address').nonempty('Email is required'),
});

export type NewsletterSignupFormValues = {
	email: string;
	name?: string;
};

export default function NewsletterSignupForm() {
	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const form = useForm<NewsletterSignupFormValues>({
		initialValues: { email: '', name: '' },
		validate: zodResolver(validationSchema),
	});

	const handleFormSubmit = async (values: NewsletterSignupFormValues) => {
		const loader = addLoader();
		try {
			const request = FetchUtils.postJson('/subscribe-to-newsletter', values);
			await request.response;
			NotificationUtils.showSuccess('Successfully subscribed to newsletter', 'Success');
			form.reset();
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Error signing up to newsletter');
		} finally {
			removeLoader(loader);
		}
	};

	return (
		<Form form={form} className="newsletter-form" onSubmit={handleFormSubmit}>
			<FormControl name="email" disabled={isLoading}>
				<TextInput type="email" placeholder="Email" />
			</FormControl>
			<button className="button quiet-large white" type="submit" disabled={isLoading}>
				JOIN OUR NEWSLETTER
			</button>
		</Form>
	);
}
